import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledPrimaryCta = styled(PrimaryCta)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
     margin-top: ${spacing(1)};
  `};
`;
