import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isIneligibleQuoteTier } from 'api/businessLogic/errors/invalidQuoteError';
import quoteClient from 'api/quoteClient';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import QuestionField from 'components/QuestionField';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { trackFieldError, trackTextInputFocus } from 'helpers/eventTracking';
import { INPUT_REGEX_ALPHANUMERIC_WITH_SPACE } from 'helpers/inputRegexes';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import { UPDATE_QUOTE } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';
import { CsQuestion } from 'types/contentStack';
import { StyledPrimaryCta } from './styles';
import useRetrieveQuoteRules from './validation';

export type csRetrieveQuote = {
  csRetrieveQuote: {
    cta_text: string;
    postcode: CsQuestion;
  };
};

export const query = graphql`
  query {
    csRetrieveQuote {
      cta_text
      postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const RetrieveQuoteForm: React.FC<{ deepId: string }> = ({ deepId }) => {
  const rules = useRetrieveQuoteRules();
  const data = useStaticQuery<csRetrieveQuote>(query);
  const { processQuestion } = useQuestionProcessor();
  const { isLoading, requestHandler } = useApiRequestHandler();
  const dispatch = useDispatch();
  const [postcode, setPostcode] = useState<string>('');

  const { getError, validateOnSubmit, showValidation } = useValidation(
    { postcode },
    rules,
    trackFieldError
  );

  const retrieveQuote = async (): Promise<void> => {
    await requestHandler(async () => {
      try {
        const quote = await quoteClient.getQuoteByDeepLink(deepId, postcode);
        dispatch({ type: UPDATE_QUOTE, quote });
        navigate(quoteAndBuyRoutes.quoteSummary(quote.quoteNumber));
      } catch (error) {
        if (isAxiosError(error) && isIneligibleQuoteTier(error)) {
          dispatch({
            type: UPDATE_ERROR,
            errorType: ErrorType.QUOTE_TIER_INVALID,
          });
        } else if (error.response?.status === 400) {
          dispatch({
            type: UPDATE_ERROR,
            errorType: ErrorType.QUOTE_NOT_FOUND,
          });
        } else {
          throw error;
        }
      }
    });
  };

  return (
    <>
      {isLoading && <LoadingOverlay loadingMessage="Retrieving your quote " />}
      <Grid>
        <GridItem desktop={6} tabletLandscape={6}>
          <form onSubmit={validateOnSubmit(retrieveQuote)}>
            <QuestionField
              question={processQuestion(data.csRetrieveQuote.postcode)}
              errorText={getError('postcode')}>
              <TextInput
                id="postcode"
                maxLength={8}
                value={postcode}
                onChange={(e) => {
                  if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SPACE)) {
                    setPostcode(e.target.value.toUpperCase());
                  }
                }}
                onBlur={() => showValidation('postcode')}
                onFocus={trackTextInputFocus('postcode')}
              />
            </QuestionField>
            <StyledPrimaryCta
              cta={{
                displayText: data.csRetrieveQuote.cta_text,
              }}
              type="submit"
            />
          </form>
        </GridItem>
      </Grid>
    </>
  );
};

export default RetrieveQuoteForm;
