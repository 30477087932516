import {
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { POSTCODE_REGEX } from 'helpers/regexes';

type CsPostcodeErrorMessages = {
  csAboutYouManualAddress: {
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csAboutYouManualAddress {
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
    }
  }
`;

const useRetrieveQuoteRules = (): ValidationRules<{ postcode: string }> => {
  const errorMessages = useStaticQuery<CsPostcodeErrorMessages>(query);

  return {
    postcode: [
      required(errorMessages.csAboutYouManualAddress.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csAboutYouManualAddress.postcode.error_messages.too_short
      ),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csAboutYouManualAddress.postcode.error_messages.invalid_format
      ),
    ],
  };
};

export default useRetrieveQuoteRules;
