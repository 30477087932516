import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import RetrieveQuoteForm from 'forms/RetrieveQuoteForm';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/usePageTracking';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import useDispatch from 'state/useDispatch';
import { CsHero, CsMeta } from 'types/contentStack';
import { RetrieveQuoteHero } from './styles';

type RetrieveQuoteProps = {
  data: {
    csRetrieveQuote: {
      meta: CsMeta;
      hero: CsHero;
    };
  };
} & PageProps;

export const query = graphql`
  query {
    csRetrieveQuote {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
    }
  }
`;

const RetrieveQuote: React.FC<RetrieveQuoteProps> = ({
  data: {
    csRetrieveQuote: {
      meta,
      hero: { heading, subheading },
    },
  },
  location,
}) => {
  const dispatch = useDispatch();
  const deepId = getQueryParam(location, 'ref');

  if (!deepId) {
    dispatch({
      type: UPDATE_ERROR,
      errorType: ErrorType.NOT_FOUND,
    });
  }

  usePageTracking(meta.meta_title);

  return (
    <Layout meta={meta} pageType="accountArea" hideAccountMenu>
      <RetrieveQuoteHero
        heading={heading}
        useHeadingAsH1
        subhead={subheading || undefined}
        pageType="secondary"
      />
      {deepId && <RetrieveQuoteForm deepId={deepId} />}
    </Layout>
  );
};

export default RetrieveQuote;
